body{
  font-size: 1.6rem;
  line-height: 1.8;
  font-weight: 200;
  text-align: justify;

  @include media_query(){
  	font-size: 1.6rem;
  }
}

.material-icons{
  vertical-align: bottom;

  @include media_query(){
  	font-size: 1.6rem;
  	vertical-align: middle;
  }
}

.scrollBlock{
  position: fixed;
  left: 0;
  width: 100%;
}


.btn_none{
  background-color: #868587 !important;
  color: #d1d0d2 !important;
  cursor: text;
  
  &:hover{
		background-color: #868587 !important;
		color: #d1d0d2 !important;
		opacity: 1 !important;
	}
}









/* header */
.header{
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background-color: $main-color;
  box-shadow: 0 0 8px #333;

  &_inner{
    display: flex;
    justify-content: flex-start;

    .icons{
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;

      align-items: center;

      &:hover{
        opacity: .7;
      }

      @include media_query(){
        width: 48px;
      	height: 48px;
      }
    }

    .btn_join{
      position: relative;
      width: 200px;
      height: 60px;

      letter-spacing: .1em;
      text-align: center;
      color: #fff;
      font-size: 2rem;
      line-height: 60px;
      background-color: #333;

      @include media_query(){
        width: 120px;
      	height: 48px;

      	line-height: 48px;
      	font-size: 1.5rem;
      }
    }

    .icon_home{
      margin-right: auto;
    }
    .material-icons{
      color: #fff;
      font-size: 3rem;
    }

    .icon_map{
      display: none;
    }

    .menu_btn{
      display: none;
      width: 48px;
      height: 48px;

      @include media_query(){
      	display: flex;
        align-items: center;
      }
    }
  }
}





/* .wrap */
.wrap{
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @include media_query(1040){
  	padding: 0 20px;
  }

  @include media_query(){
  	display: block;
  	padding: 0;
  }
}





.btn_ticket{
  display: block;
  width: 260px;
  padding: 20px 0;

  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 1.9rem;
  letter-spacing: .1em;

  @include media_query(){
    width: 240px;
  	margin: 0 auto;
  	padding: 15px 0;
  	font-size: 1.7rem;
  }
}










/* .main */
.main{
  width: 69.23%;
  flex: 1;

  @include media_query(){
  	width: 100%;
  }
}

.article{
  padding: 60px 0 0 20px;
  margin-top: -60px;
  margin-bottom: 50px;

  @include media_query(){
  	padding: 0;
  	margin-top: -43px;
  	padding-top: 43px;
  }

  &_inner{
    padding: 30px 20px 0;

    border-left: solid 5px $main-color;

    @include media_query(){
    	padding: 5px 0 0;
    	border: none;
    }
  }
}

.cancel_table{
  margin-top: 10px;

  td,th{
    border: solid 1px #ccc;
    padding: .5em .8em;
  }
}

.section{

  &_title{
    min-width: 350px;
    display: inline-block;
    padding: 13px 18px;
    margin: 0 0 30px -45px;

    font-size: 2rem;
    font-weight: 200;
    background-color: $main-color;
    color: #fff;

    @include media_query(){
    	min-width: 100%;
    }

    &:before{
      display: inline-block;
      width: 25px;
      height: 26px;
      margin-right: 8px;
      content: "";

      vertical-align: text-bottom;
      background-image: url(../img/icon_momiji.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @include media_query(){
      	display: block;
      	width: 100%;
      	margin-bottom: 10px;

      	background-size: 25px 26px;
      }
    }

    @include media_query(){
    	margin: 0 0 20px;
    	width: 100%;

    	font-size: 1.6rem;
    	text-align: center;
    }
  }

  &_text{
    p:not(:last-child){
      margin-bottom: 1em;
    }
  }

  &_inner{

    &:not(:last-child){
      margin-bottom: 50px;

      @include media_query(){
      	margin-bottom: 30px;
      }
    }

    p:not(:last-child){
      //margin-bottom: 1em;
    }

    @include media_query(){
    	padding: 0 20px;
    }

    .sub_header{
      padding-bottom: 3px;
      margin-bottom: 15px;

      border-bottom: solid 1px #333;
      font-size: 1.6rem;

      @include media_query(){
      	margin-bottom: 20px;
      }
    }

    &_title{
      margin-bottom: 25px;

      font-size: 2.6rem;
      font-weight: 400;
      line-height: 1.5;

      @include media_query(){
      	margin-bottom: 10px;

      	font-size: 2rem;
      }
    }

    .section_inner_text{
      p{
        line-height: 1.7;

        &:not(:last-child){
          margin-bottom: 1em;

          @include media_query(){
          	margin-bottom: 0;
          }
        }
      }
    }
  }
}


.inner_title{
  font-size: 2rem;
  color: $main-color;
  margin: 40px 0 10px;
}

.section_inner_video{
  iframe{
    max-width: 100% !important;
    max-height: auto !important;
  }
}

.linka{
  text-decoration: underline;
  
  &:hover{
    text-decoration: none;
  }
/*
  &::before{
    content: "> ";
  }
*/
}

.span_title{
  font-weight: bold;
  margin-bottom: 10px;
}

.section_inner_text_ul{
  padding-left: 1.5em;
  margin: 1em 0;
  li{
    list-style: disc;
  }
}





/* テーブルテンプレート */
%table{
  width: 100%;
  border-top: solid 1px #bbb;
  border-bottom: solid 1px #bbb;

  tr{
    border-bottom: solid 1px #bbb;
  }

  th{
    min-width: 100px;
    padding: .6em 1em;
    text-align: left;
    padding-left: 0;
    vertical-align: middle;

    &:not(:last-child){
      border-right: dashed 1px #bbb;
    }

    @include media_query(){
    	min-width: 100%;
    }
  }

  td{
    padding: .6em 1em;
    vertical-align: middle;

    &:not(:last-child){
      border-right: dashed 1px #bbb;
    }
  }
}











/* .sidebar */
.sidebar{
  //width: 26.923%;
  flex-basis: 280px;
  overflow: hidden;

  @include media_query(){
  	width: 100%;
  	padding: 0 20px;
  }

  iframe{
    width: 100% !important;
  }

  .tweet_title{
    margin-bottom: 10px;
    font-size: 1.8rem;
  }

  .twitter{
    width: 100%;
    height: 502px;
    border: solid 1px #ccc;
    margin-bottom: 30px;
  }
}

nav{
  @include media_query(){
    display: none;

  	position: fixed;
  	top: 48px;
  	left: 0;
  	width: 100%;
  	z-index: 900;

  	.gnav{
    	margin-bottom: 0;
  	}

  	li{
      a{
        display: block;
        padding: 10px;
        background-color: #ECE7E7;
        color: #333;

        &:hover{
          background-color: $main-color;
        }
      }
    }

    .subnav{
      li{
        padding: 0;

        a:hover{
          opacity: 1;
        }
      }
    }
  }
}

.gnav{
  margin-bottom: 10px;

  li{
    a{
      display: block;
      padding: 10px;
      background-color: #ECE7E7;
      color: #333;

      &:hover{
        background-color: $main-color;
        color: #fff;
      }
    }
  }

  .on a{
    background-color: $main-color;
    color: #fff;
  }
}

.subnav{
  li{
    padding: 10px;

    a:hover{
      opacity: .5;
    }
  }
}

.nav_fixed{
  position: fixed;
  top: 60px;
  width: 280px;
  z-index: 200;

  @include media_query(){
  	//position: static;
  	width: 100%;
  	top: auto;
  }
}

.nav_stop{
  position: absolute;
  width: 280px;
  z-index: 200;
}









/* .footer */
.footer{
  background-color: #ECE7E7;
  margin-top: 50px;
  position: relative;
  z-index: 300;

  &_inner{
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px 20px;

    h3{
      font-size: 2rem;
      font-weight: 200;
      margin-bottom: 20px;

      @include media_query(){
       font-size: 1.6rem;
       margin-bottom: 10px;
      }
    }
  }

  .sponsored{
    padding-top: 60px;
    margin-top: -60px;

    img{
      max-width: 100%;
      height: auto;
    }

    li{
      margin-bottom: 2%;

      a:hover{
        opacity: .5;
      }
    }

    &_l{

    }

    &_m{
      display: flex;
      flex-wrap: wrap;
      margin-left: -2%;
      @include media_query(){
      	margin-left: 0;
      }

      li{
        width: 48%;
        margin-left: 2%;
        @include media_query(){
        	width: 100%;
        	margin-left: 0;
        }
      }
    }

    &_s{
      display: flex;
      flex-wrap: wrap;
      margin-left: -2%;

      li{
        width: 31.33%;
        margin-left: 2%;

        @include media_query(){
        	width: 48%;
        }
      }
    }
  }

  &_info{
    padding: 30px 0;

    background-color: #333;
    text-align: center;
    line-height: 2em;
    color: #fff;

    a{
      color: #fff;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }
  }
}

.link{
  h3{
    margin-top: 30px;

    @include media_query(){
    	margin-top: 15px;
    }
  }
}


.page{
  padding-top: 80px;

  @include media_query(){
  	padding-top: 48px;
  }
}


.report{
  .section_inner{
    img{
      width: 100%;
      height: auto;
    }
    h3 small{
      font-size: .8em;
    }
  }
  ul{
    margin: 1em 0;
    padding-left: 1.5em;
    
    li{
      list-style: disc;
    }
  }
  h4{
    font-size: 1.8rem;
    margin: 1.5em 0 .5em;
  }
  
  .section_inner_title_sub{
    font-size: 1.2em;
    margin: -20px 0 20px 0;
  }
}




















.ripple {
  overflow: hidden;
}

.ripple .rp-effect {/*エフェクト*/
  position: absolute;
  border-radius: 50%;
  opacity: 0.35;/*波紋の濃さ*/
  transform: scale(0);
  background: #c2c4c4;/*波紋色*/
  animation: ripple 800ms;

  display: block;
  color: #000;
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2.0);
  }
}



/* Google Material icons */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons.md-light{
  color: #fff;
}


.mbn{
  margin-bottom: 0 !important;
}
