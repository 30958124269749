.sponsored_table{
  width: 100%;
  margin-bottom: 10px;
  
  th,td{
    padding: .5em 1em;
    border: solid 1px #bbb;
    vertical-align: middle;
  }
  
  tr{
    &:nth-child(even){
      background-color: #efefef;
    }
    
    &:nth-child(2){
      th{
        width: 31%;
      }
      td{
        width: 23%;
      }
    }
  }
}