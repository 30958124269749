.list{
  padding-left: 2.2rem;
  
  &:not(:last-child){
    margin-bottom: 1em;
  }
  
  li{
    list-style: disc;
    
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }
}

.member{
  li a{
    margin-left: 1em;
  }
}