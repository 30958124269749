/* メインビジュアル */
.hero_img{
  margin-bottom: 50px;
  background-color: #CEC0C0;
  
  @include media_query(){
  	margin-bottom: 0;
  }
  
  &_inner{
    max-width: 1280px;
    margin: 0 auto;
    min-height: 561px;
    
    position: relative;
    background: url(../img/top_img_vol10_001.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    text-align: center;
    
    @include media_query(){
      max-height: 100vh;
      min-height: 558px;
    	background: url(../img/top_img_vol10_001_sp.png);
    	background-size: 320px auto;
    	background-repeat: no-repeat;
    	background-position: center center;
    } 
  }
  
  .btn_ticket{
    position: absolute;
    bottom: 7%;
    right: 54.453125%;
    
    @include media_query(){
    	bottom: 23%;
    	left: 0;
    	right: 0;
    	margin: auto;
    }
  }
}


.recommend{
  padding-left: 1.2em;
  
  li{
    list-style: disc;
    
    &:not(:last-child){
      margin-bottom: .5em;
    }
  }
}







/* セッション・出演者紹介 */
.speaker_session{
  padding-top: 60px;
  margin-top: -60px;
  
  @include media_query(){
  	padding-top: 48px;
  	margin-top: -48px;
  }
  
  &:not(:last-child){
    margin-bottom: 80px;
    
    @include media_query(){
    	margin-bottom: 30px;
    }
  }
}

.speaker{
  margin-top: 30px;
  padding: 30px;
  
  background-color: #ECE7E7;
  
  @include media_query(){
  	margin-top: 30px;
  }
  
  a{
    text-decoration: underline;
    
    &:hover{
      text-decoration: none;
    }
  }
  
  &_header{
    display: flex;
    margin-bottom: 20px;
    
    @include media_query(){
    	display: block;
    	text-align: center;
    }
  }
  
  &_img{
    max-width: 150px;
    height: 150px;
    margin-right: 20px;
    
    @include media_query(){
    	margin: 0 auto;
    	margin-bottom: 10px;
    }
    
    img{
      width: 100%;
      height: auto;
    }
  }
  
  &_info{
    &_name{
      //margin-bottom: 10px;
      
      font-size: 2.6rem;
      
      @include media_query(){
      	//margin-bottom: 5px;
      	
      	font-size: 2rem;
      }
    }
    
    &_kana{
      margin-bottom: 10px;
      
      @include media_query(){
      	font-size: 1.3rem;
      }
    }
    
    &_company{
      li:not(:last-child){
        margin-bottom: 5px;
      }
    }
  }
  
  &_introduction{
    line-height: 1.7;
    margin-bottom: 35px;
    
    p:not(:last-child){
      margin-bottom: 1em;
    }
  }
  
  &_books{
    
    h4{
      margin-bottom: 10px;
      
      font-weight: bold;
      color: $main-color;
    }
    
    li:not(:last-child){
      margin-bottom: 5px;
    }
  }
}










/* 開催概要 */
.outline_table{
  display: block;
  width: 100%;
  border-top: solid 1px #bbb;
  border-bottom: solid 1px #bbb;
  
  @include media_query(){
  	border: solid 1px #bbb;
  	
  	tbody,tr,td,th{
    	display: block !important;
    	width: 100% !important;
    	word-break: break-all !important;
  	}
  }
  
  tr{
    border-bottom: solid 1px #bbb;
    
    @include media_query(){
    	display: block;
    }
    
    &:last-child{
      @include media_query(){
      	border-bottom: none;
      }
    }
    
    &:last-child{
      border: none;
    }
  }
  
  th{
    border-right: dashed 1px #bbb;
    text-align: left;
    font-weight: 200;
    padding: .6em 1em .6em 0;
    
    @include media_query(){
    	display: block;
    	text-align: center;
    	
    	border-right: none;
    	border-bottom: dashed 1px #bbb;
    	background-color: #ECE7E7;
    	padding: .5em 0;
    }
  }
  
  td{
    padding: .6em 1em;
    
    line-height: 1.7;
    
    a:hover{
      opacity: .5;
    }
    
    img{
      vertical-align: middle;
    }
    
    .material-icons{
      vertical-align: middle;
    }
    
    .go_map{
      margin-left: 10px;
      
      .material-icons{
        font-size: 1.8rem;
      }
      
      @include media_query(){
      	display: block;
      }
    }
    
    li{
      &:not(:last-child){
        margin-bottom: 5px;
      }
    }
    
    @include media_query(){
    	display: block;
    	text-align: center;
    }
  }
}







.table_wrap{
  //overflow: scroll;
  overflow-x:scroll;
  width: 100%;
  
  .price_table{
    @include media_query(){
    	min-width: 650px;
    }
  }
  @include media_query(680){
    &::-webkit-scrollbar{height:8px;}
    &::-webkit-scrollbar-track{background: #d3d3d3;}
    &::-webkit-scrollbar-thumb{background: $main-color;}
  }
}


/* 参加費・お申し込み方法 */
.price_table{
  @extend %table;
  
  &:not(:last-child){
    margin-bottom: 30px;
  }
  
  tr{
    
    th{
      min-width: 120px;
      white-space: nowrap;
      
      @include media_query(){
      min-width: auto;
      }
    }
    
    &:first-child th{
      text-align: center;
    }
  
    td{
      text-align: center;
      
      &:last-child{
        text-align: left;
      }
      
      &:nth-child(2){
        white-space: nowrap;
      }
    }
  }
}
.cap_text{
  margin-top: 20px;
  margin-bottom: 50px;
  
  @include media_query(){
  	margin-bottom: 30px;
  }
}

.party_table{
  @extend %table;
  
  margin-bottom: 50px;
  
  @include media_query(){
    margin-top: 10px;
    
    border: solid 1px #bbb;
    
  	tr{
    	display: block;
    	
    	th,td{
      	display: block;
      	text-align: center;
    	}
    	
    	th{
      	border-bottom: dashed 1px #bbb;
      	background-color: #ECE7E7;
      	padding: .5em 0;
      	
      	&:not(:last-child){
          border-right: none;
        }
    	}
    }
  }
}

.payment_text{
  margin-bottom: 25px;
}


.time_table{
  @extend %table;
  
  margin-bottom: 50px;
  
  .break{
    background-color: #f2f2f2;
  }
  
  @include media_query(){
    margin-top: 10px;
    
    border: solid 1px #bbb;
    	
  	th{
    	white-space: nowrap;
    	padding: 1em;
  	}
  }
}








/* アクセス */
.map{
  margin-top: 25px;
  
  iframe{
    margin-bottom: 20px;
  }
  
  a{
    &:hover{
      opacity: .5;
    }
    .material-icons{
      vertical-align: bottom;
      
      @include media_query(){
      	vertical-align: middle;
      }
    }
  }
}


.follow{
  padding-top: 70px !important;
  margin-top: -70px;
  
  h3{
    font-size: 2rem;
  }
  
  ul{
    padding-left: 1.2em;
    margin-top: 10px;
    
    li{
      list-style: disc;
      
      span{
        //color: $accent-color;
      }
    }
  }
}









/* よくある質問 */
/*
.qa:not(:last-child){
  margin-bottom: 50px;
  
  @include media_query(){
  	margin-bottom: 30px;
  }
}
*/

.question{
  dt{
    margin-bottom: 10px;
    
    font-weight: bold;
    
    &:before{
      content: "Q";
      margin-right: .5em;
    }
  }
  
  dd{
    a:hover{
      opacity: .5;
    }
    
    &:not(:last-child){
      margin-bottom: 25px;
    }
  }
}