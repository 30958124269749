html{
	font-size: 62.5%;
}

body{
	color: $font-color;
	font-family: $font-family;
	line-height: $line-height;
	background-color: $bg-color;
}

::-moz-selection, ::selection{
	background: #b3d4fc;
	text-shadow: none;
}

@for $i from 1 through 6 {
  h#{$i} {
    font-weight: normal;
  }
}

a{
  color: $accent-color;
  text-decoration: none;
}

button,
input,
select,
textarea {
    color: $font-color;
    
    &:focus{
	    outline: none;
    }
}

textarea {
    resize: vertical;
}

label,
input[type=submit]{
    cursor: pointer;
}